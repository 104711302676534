<template>
  <div
    class="h-full w-full"
    :class="[
      isDarkMode ? 'bg-gray-900 text-gray-100' : 'bg-gray-100 text-gray-900',
    ]"
  >
    <div
      class="py-8 lg:py-16 px-6 lg:px-24"
      :class="[
        isDarkMode ? 'bg-gray-900 text-gray-100' : 'bg-gray-100 text-gray-900',
      ]"
    >
      <div class="xl:container grid grid-cols-6 px-2 pt-12 md:px-8 md:pt-8">
        <div class="col-span-2 md:col-span-1">
          <div
            class="h-28 w-28 rounded-full shadow-xl border-2 border-white"
            style="
              background-image: url('/img/pooyan.png');
              background-size: 130%;
              background-position: center top;
            "
          ></div>
          <div
            class="
              hidden
              md:block
              transform
              -rotate-90
              w-64
              border-b-2 border-yellow-500
              text-right
              pr-2
              mt-48
              -ml-20
            "
          >
            <span
              class="
                bg-yellow-500
                px-12
                pt-2
                text-gray-100
                rounded-t-lg
                font-bold
                text-l
                uppercase
              "
            >
              {{ currentRouteName }}
            </span>
          </div>
        </div>
        <div class="col-span-4 px-8 pt-10 md:col-span-5 md:px-4 md:pt-0">
          <div class="flex w-full">
            <div
              class="flex-1 text-xl md:text-2xl font-extrabold mb-2"
              :class="[isDarkMode ? 'text-white' : 'text-black']"
            >
              Pooyan Razian
            </div>
            <div class="flex-none hidden md:block mb-2">
              <label class="switch">
                <input
                  type="checkbox"
                  @change="toggleDarkMode"
                  :checked="!isDarkMode"
                />
                <span
                  class="slider round border-2"
                  :class="[
                    isDarkMode
                      ? 'bg-gray-700 border-gray-600'
                      : 'bg-gray-300 border-gray-100',
                  ]"
                >
                  <font-awesome-icon
                    :icon="['fa', 'moon']"
                    v-if="!isDarkMode"
                    class="ml-2 mt-2"
                  />
                  <font-awesome-icon
                    :icon="['fa', 'sun']"
                    v-if="isDarkMode"
                    class="text-yellow-400 ml-8 mt-2"
                  />
                </span>
              </label>
            </div>
          </div>
          <nav class="hidden md:block border-t-2 text-right select-none">
            <router-link
              :class="[
                isDarkMode
                  ? 'text-gray-100 hover:text-gray-400'
                  : 'text-gray-600 hover:text-black',
              ]"
              class="
                uppercase
                font-semibold
                no-underline
                tracking-wider
                cursor-pointer
              "
              to="/"
              >About
            </router-link>
            <span class="px-2">|</span>
            <router-link
              :class="[
                isDarkMode
                  ? 'text-gray-100 hover:text-gray-400'
                  : 'text-gray-600 hover:text-black',
              ]"
              class="
                uppercase
                font-semibold
                no-underline
                tracking-wider
                cursor-pointer
              "
              to="/articles"
              >Articles
            </router-link>
            <span class="px-2">|</span>
            <router-link
              :class="[
                isDarkMode
                  ? 'text-gray-100 hover:text-gray-400'
                  : 'text-gray-600 hover:text-black',
              ]"
              class="
                uppercase
                font-semibold
                no-underline
                tracking-wider
                cursor-pointer
              "
              to="/career"
              >Career
            </router-link>
            <span class="px-2">|</span>
            <router-link
              :class="[
                isDarkMode
                  ? 'text-gray-100 hover:text-gray-400'
                  : 'text-gray-600 hover:text-black',
              ]"
              class="
                uppercase
                font-semibold
                no-underline
                tracking-wider
                cursor-pointer
              "
              to="/certificates"
              >Certificates
            </router-link>
            <span class="px-2">|</span>
            <router-link
              :class="[
                isDarkMode
                  ? 'text-gray-100 hover:text-gray-400'
                  : 'text-gray-600 hover:text-black',
              ]"
              class="
                uppercase
                font-semibold
                no-underline
                tracking-wider
                cursor-pointer
              "
              to="/contact"
              >Contact
            </router-link>
          </nav>
          <main class="hidden md:block pt-16">
            <router-view />
          </main>
        </div>
      </div>
      <main class="md:hidden px-3 pt-12 pb-12">
        <router-view />
      </main>
      <div
        class="
          md:hidden
          fixed
          top-0
          left-0
          h-12
          w-full
          border-b-2 border-white
          shadow-md
        "
        :class="[isDarkMode ? 'bg-gray-800' : 'bg-gray-200']"
      >
        <div class="flex w-full">
          <div class="inline-block flex-shrink-0 px-2 pt-1" @click="toggleMenu">
            <div
              class="burger-button-bar"
              :class="[isDarkMode ? 'bg-white' : 'bg-black']"
            ></div>
            <div
              class="burger-button-bar"
              :class="[isDarkMode ? 'bg-white' : 'bg-black']"
            ></div>
            <div
              class="burger-button-bar"
              :class="[isDarkMode ? 'bg-white' : 'bg-black']"
            ></div>
          </div>
          <div class="inline-block flex-grow text-right pt-4">
            <div class="inline-block h-5 border-l-2 px-4 border-gray-100">
              <a class="mr-2" href="https://www.linkedin.com/in/prazian/"
                ><font-awesome-icon :icon="['fab', 'linkedin']"
              /></a>
              <a class="mx-2" href="https://github.com/prazian"
                ><font-awesome-icon :icon="['fab', 'github']"
              /></a>
              <a class="ml-2" href="https://twitter.com/pooyan_razian">
                <font-awesome-icon :icon="['fab', 'twitter']" />
              </a>
            </div>
            <div
              class="
                inline-block
                h-5
                border-l-2
                px-4
                border-gray-100
                text-sm
                cursor-pointer
              "
              @click="toggleDarkMode()"
            >
              <font-awesome-icon :icon="['fa', 'moon']" v-if="!isDarkMode" />
              <font-awesome-icon
                :icon="['fa', 'sun']"
                v-if="isDarkMode"
                class="text-yellow-400"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        :class="menuOpen ? 'visible' : 'invisible -ml-72'"
        class="
          md:hidden
          z-30
          fixed
          top-0
          left-0
          h-full
          bg-gray-800
          transition-all
          duration-300
          ease-out
        "
      >
        <nav class="select-none">
          <div class="" @click="toggleMenu">
            <div
              class="
                absolute
                bg-gray-700
                rounded-full
                border-gray-500 border-2
                p-4
                top-0
                right-0
                cursor-pointer
                flex flex-col
                items-center
                text-white text-sm
                mt-6
                -mr-6
              "
            >
              <svg
                class="fill-current text-white"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                ></path>
              </svg>
            </div>
          </div>
          <router-link
            @click="toggleMenu"
            class="
              block
              text-left
              align-middle
              py-8
              px-14
              border-b-2 border-gray-400
              capitalize
              text-2xl text-gray-100
              font-semibold
              no-underline
              hover:text-gray-200
              tracking-wider
            "
            to="/"
            >About
          </router-link>
          <router-link
            @click="toggleMenu"
            class="
              block
              text-left
              align-middle
              py-8
              px-14
              border-b-2 border-gray-400
              capitalize
              text-2xl text-gray-100
              font-semibold
              no-underline
              hover:text-gray-200
              tracking-wider
            "
            to="/articles"
            >Articles
          </router-link>
          <router-link
            @click="toggleMenu"
            class="
              block
              text-left
              align-middle
              py-8
              px-14
              border-b-2 border-gray-400
              capitalize
              text-2xl text-gray-100
              font-semibold
              no-underline
              hover:text-gray-200
              tracking-wider
            "
            to="/career"
            >Career
          </router-link>
          <router-link
            @click="toggleMenu"
            class="
              block
              text-left
              align-middle
              py-8
              px-14
              border-b-2 border-gray-400
              capitalize
              text-2xl text-gray-100
              font-semibold
              no-underline
              hover:text-gray-200
              tracking-wider
            "
            to="/certificates"
            >Certificates
          </router-link>
          <router-link
            @click="toggleMenu"
            class="
              block
              text-left
              align-middle
              py-8
              px-14
              border-b-2 border-gray-400
              capitalize
              text-2xl text-gray-100
              font-semibold
              no-underline
              hover:text-gray-200
              tracking-wider
            "
            to="/contact"
            >Contact
          </router-link>
        </nav>
      </div>
    </div>
  </div>
  <cookie-modal />
  <div
    :class="[
      isDarkMode ? 'bg-gray-800 md:bg-gray-800' : 'bg-gray-200 md:bg-gray-800',
    ]"
    class="
      md:hidden
      fixed
      left-0
      right-0
      bottom-0
      w-screen
      border-white
      py-7
      z-0
      border-t-2
    "
  >
    <span
      :class="[isDarkMode ? 'text-gray-100' : 'text-gray-900']"
      class="flex flex-wrap pl-8 uppercase text-xs"
    >
      Copyright &copy; {{ currentYear }} - pooyan.info
    </span>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import CookieModal from "@/components/CookieModal.vue";

library.add(faMoon, faSun);

@Options({
  data() {
    return {
      menuOpen: false,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    isDarkMode() {
      return this.$store.getters.isDarkMode;
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    toggleDarkMode() {
      this.$store.dispatch("toggleDarkMode").then(() => {
        if (this.isDarkMode) {
          document.documentElement.classList.add("dark");
        } else {
          document.documentElement.classList.remove("dark");
        }
      });
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        document.title = "Pooyan - " + this.currentRouteName || "Pooyan";
      },
    },
  },
  components: {
    CookieModal,
  },
})
export default class App extends Vue {}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.grecaptcha-badge {
  bottom: 0.5rem !important;
  left: -5rem !important;
  display: none;
}

.burger-button-bar {
  width: 35px;
  height: 5px;
  margin: 6px 0;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2196f3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #cccccc;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.code-inline {
  @apply font-nunito-sans text-sm rounded-sm tracking-widest px-2 bg-gray-200 dark:bg-gray-800;
}
.code {
  @apply block whitespace-pre overflow-x-scroll font-nunito-sans text-sm rounded-md leading-relaxed py-3 bg-gray-300 dark:bg-gray-700;
}
</style>
